import React, { useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Helmet } from 'react-helmet';
import { bool } from 'prop-types';
import TopSection from './components/TopSection';
import HowItWorks from './components/HowItWorks';
import TrueCash from './components/TrueCash';
import Security from './components/Security';
import Testimonials from './components/Testimonials';
import CirclePeople from './components/CirclePeople';
import BottomForm from './components/BottomForm';
import BankListSection from './components/BankListSection';
import FaqPage from '../faq';
import TopSectionSmallBusiness from './components/TopSectionSmallBusiness';

const Welcome = ({ isSmallBusiness }) => {
  const size = useContext(ResponsiveContext);
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isXlarge = size === 'xlarge';

  return (
    <Box
      justify="center"
      align="center"
      fill="horizontal"
      overflow="hidden"
    >
      <Helmet>
        <title>Choose the best credit card with CleverFox</title>
        <meta name="description" content="CleverFox is the easiest way to find the most valuable credit card" />
        <script type="application/ld+json">{'{"@context": "https://schema.org","@type": "WebSite","name": "Cleverfox Financial","url": "https://www.askcleverfox.com/"}'}</script>
      </Helmet>
      <Box fill="horizontal">
        {isSmallBusiness ? (
          <TopSectionSmallBusiness
            isSmall={isSmall}
            isMedium={isMedium}
          />
        ) : (
          <TopSection
            isSmall={isSmall}
            isMedium={isMedium}
          />
        )}
      </Box>
      <Box
        fill="horizontal"
        align="center"
      >
        <CirclePeople isSmall={isSmall} isMedium={isMedium} />
      </Box>
      <Box fill>
        <HowItWorks
          isSmall={isSmall}
          isMediumDown={isMedium || isSmall}
        />
      </Box>
      <Box
        fill="horizontal"
        align="center"
        border={{ side: 'bottom', color: 'borderGrey' }}
      >
        <BankListSection isMediumDown={isMedium || isSmall} />
      </Box>
      <Box fill>
        <TrueCash
          isSmall={isSmall}
          isMedium={isMedium}
          isSmallBusiness={isSmallBusiness}
        />
      </Box>
      <Box fill>
        <Security isMedium={isMedium} isSmall={isSmall} />
      </Box>
      <Box fill align="center">
        <FaqPage shouldShowShortList={true} />
      </Box>
      <Box fill>
        <Testimonials />
      </Box>
      <Box fill background="cfPurple600">
        <BottomForm
          isSmall={isSmall}
          isMedium={isMedium}
          isXlarge={isXlarge}
          isSmallBusiness={isSmallBusiness}
        />
      </Box>
    </Box>
  );
};

export default Welcome;

Welcome.propTypes = {
  isSmallBusiness: bool,
};

Welcome.defaultProps = {
  isSmallBusiness: false,
};
