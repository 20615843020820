import React from 'react';
import {
  Box, Heading,
  Text, Button,
} from 'grommet';
import { bool } from 'prop-types';
import amplitude from 'amplitude-js';
import { useNavigate } from 'react-router-dom';
import copy from '../../../utils/copy.json';
import colors from '../../../styles/colors';
import { noOrphans, sendCustomEvent } from '../../../utils';

const TopSectionSmallBusiness = ({ isSmall, isMedium }) => {
  const isMediumDown = isMedium || isSmall;
  const topBackground = 'https://img.askcleverfox.com/imagery/bg_pattern01.png';
  const navigate = useNavigate();

  const onClickDemoButton = ({
    demoMask,
    demoPath,
  }) => {
    const eventName = 'welcome.demo-button-smallbusiness.click';
    const gtmPayload = {
      event: eventName,
      meta: {
        demoType: demoPath,
      },
    };
    amplitude.getInstance().logEvent(eventName);
    sendCustomEvent(gtmPayload);

    return navigate(
      `/calculator/${demoPath || ''}`,
      {
        state: {
          lastFour: demoMask,
        },
      },
    );
  };

  return (
    <Box
      background={{
        image: `url(${topBackground})`,
        color: `${colors.cfPurple500}`,
        size: 'cover',
      }}
      pad={{ horizontal: 'large', vertical: isMediumDown ? 'xlarge' : 'xxxlarge' }}
      align="center"
    >
      <Box
        gap="large"
        width={{ max: isMediumDown ? '100%' : '55%' }}
      >
        <Heading textAlign="center" color="cfWhite" level="1" margin="none">
          {noOrphans(copy.welcome.topHeadingSB)}
        </Heading>
        <Box>
          <Text textAlign="center" size="medium" color="cfWhite">
            {copy.welcome.topSubHeadingOneSB}
            <Text size="medium" weight={600} color="cfGreen500">
              {copy.welcome.topSubHeadingTwo}
            </Text>
            {noOrphans(copy.welcome.topSubHeadingThreeSB)}
          </Text>
        </Box>
        <Box align="center" fill="horizontal">
          <Button
            as="a"
            href="/calculator/business"
            primary={true}
            onClick={(e) => {
              e.preventDefault();
              onClickDemoButton({ demoPath: 'business', demoMask: '-2343' });
            }}
            label={<Text weight={600}>Start Saving Now</Text>}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TopSectionSmallBusiness;

TopSectionSmallBusiness.propTypes = {
  isSmall: bool,
  isMedium: bool,
};

TopSectionSmallBusiness.defaultProps = {
  isSmall: false,
  isMedium: false,
};
