import React from 'react';
import {
  Box,
  Heading,
  Text,
} from 'grommet';
import { bool } from 'prop-types';
import copy from '../../../utils/copy.json';
import PeopleSittingIcon from '../../../assets/icons/PeopleSittingIcon';

const TrueCash = ({ isSmall, isMedium, isSmallBusiness }) => (
  <Box
    background="white"
    pad="large"
    className="max-page-content"
    fill="horizontal"
    align="center"
    alignSelf="center"
    justify="between"
    direction={isSmall || isMedium ? 'column' : 'row-reverse'}
  >
    <Box gap="large" width={{ max: isSmall || isMedium ? '100%' : '40%' }}>
      <Box>
        <Heading level="2" size="large" color="cfPurple900" weight={700} margin={{ top: 'none' }}>
          {isSmallBusiness ? copy.welcome.trueCashHeadingSB : copy.welcome.trueCashHeading}
        </Heading>
        <Box gap="medium">
          <Text>
            {isSmallBusiness ? copy.welcome.trueCashOneSB : copy.welcome.trueCashOne}
          </Text>
          <Text>
            {isSmallBusiness ? copy.welcome.trueCashTwoSB : copy.welcome.trueCashTwo}
          </Text>
        </Box>
      </Box>
    </Box>

    <Box
      pad={{ top: isSmall ? 'medium' : '' }}
      width={isSmall ? '300px' : '500px'}
      align="center"
      justify="center"
    >
      <PeopleSittingIcon />
    </Box>
  </Box>
);

export default TrueCash;

TrueCash.propTypes = {
  isSmall: bool,
  isMedium: bool,
  isSmallBusiness: bool,
};

TrueCash.defaultProps = {
  isSmall: false,
  isMedium: false,
  isSmallBusiness: false,
};
