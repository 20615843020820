import React, { lazy, Suspense } from 'react';
import { bool } from 'prop-types';
import {
  Box, Main,
  ResponsiveContext,
  Layer,
} from 'grommet';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Login from '../pages/login';
import RequireAuth from './auth/RequireAuth';
import Welcome from '../pages/welcome';
import AppError from './Error';
import { mapStateToProps, mapDispatchToProps } from '../App.config';
import colors from '../styles/colors';
import { setCampaignData } from '../reducers/actions';
import DemoCardSelectorWrapper from '../pages/demo/DemoCardSelectorWrapper';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport) => new Promise((resolve, reject) => {
  // check if the window has already been refreshed
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
  );
  // try to import the component
  componentImport().then((component) => {
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
    resolve(component);
  }).catch((error) => {
    if (!hasRefreshed) { // not been refreshed yet
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
      return window.location.reload(); // refresh the page
    }
    reject(error); // Default error behaviour as already tried refresh
    return 1;
  });
});

const About = lazy(() => lazyRetry(() => import('../pages/about')));
const FaqPage = lazy(() => lazyRetry(() => import('../pages/faq')));
const Dashboard = lazy(() => lazyRetry(() => import('../pages/dashboard')));
const DemoDashboard = lazy(() => lazyRetry(() => import('../pages/demo/DemoDashboard')));
const Results = lazy(() => lazyRetry(() => import('../pages/results')));
const Profile = lazy(() => lazyRetry(() => import('../pages/profile')));
const Leaderboard = lazy(() => lazyRetry(() => import('../pages/leaderboard')));

// lazy only works with default exports
const PostPage = lazy(() => lazyRetry(() => import('../pages/blog/components/postPage')));
const PostsPage = lazy(() => lazyRetry(() => import('../pages/blog/components/postsPage')));
const ReviewPage = lazy(() => lazyRetry(() => import('../pages/reviews/ReviewPage')));
const ReviewsPage = lazy(() => lazyRetry(() => import('../pages/reviews')));

export const MainContentSectionComp = ({
  appLoading,
  appError,
}) => {
  const size = React.useContext(ResponsiveContext);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [isLoading, setIsLoading] = React.useState(appLoading);
  const [isError, setIsError] = React.useState(appError);
  const urlParams = new URLSearchParams(search);
  const utm = urlParams.get('utm_campaign');
  if (utm) {
    dispatch(setCampaignData({ id: utm }));
  }

  React.useEffect(() => {
    setIsLoading(appLoading);
  }, [appLoading]);

  React.useEffect(() => {
    setIsError(appError);
  }, [appError]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isSmall = size === 'small';

  const topPadConfigResults = isSmall ? '55px' : '65px';
  const topPadConfigOther = isSmall ? '15px' : '50px';

  const topPadConfig = pathname.includes('results') ? topPadConfigResults : topPadConfigOther;
  const topMarginConfig = pathname.includes('results') ? '26px' : '36px';

  if (isError) {
    return (
      <Box
        className="main-content"
        align="center"
        margin="auto"
        justify="center"
      >
        <AppError />
      </Box>
    );
  }

  return (
    <Box
      pad={{
        top: topPadConfig,
      }}
      fill
    >
      {isLoading && (
        <Layer
          full
          animation="fadeIn"
          background={{
            color: 'cfPurple100',
            opacity: '0.2',
            size: 'cover',
          }}
        >
          <Box
            align="center"
            justify="center"
            fill
          >
            <BeatLoader
              loading={true}
              color={colors.cfPurple500}
            />
          </Box>
        </Layer>
      )}
      <Main
        align="center"
        fill
        margin={{ top: isSmall ? topMarginConfig : 'none' }}
        height={{ min: '600px' }}
        overflow="visible"
      >
        <Routes>
          <Route path="/" element={(<Welcome />)} />
          <Route path="/smallbusiness" element={(<Welcome isSmallBusiness={true} />)} />

          <Route
            path="guides"
            element={<Suspense fallback={<> </>}><PostsPage /></Suspense>}
          />
          <Route
            path="guides/:contentType"
            element={<Suspense fallback={<> </>}><PostsPage /></Suspense>}
          />
          <Route
            path="guides/:contentType/:category"
            element={<Suspense fallback={<> </>}><PostsPage /></Suspense>}
          />
          <Route
            path="guides/:contentType/:category/:slug"
            element={<Suspense fallback={<> </>}><PostPage /></Suspense>}
          />

          <Route
            path="news"
            element={(
              <Suspense fallback={<> </>}>
                <PostsPage />
              </Suspense>
            )}
          />
          <Route
            path="news/:contentType"
            element={<Suspense fallback={<> </>}><PostsPage /></Suspense>}
          />
          <Route
            path="news/:contentType/:category"
            element={<Suspense fallback={<> </>}><PostsPage /></Suspense>}
          />
          <Route
            path="news/:contentType/:category/:slug"
            element={<Suspense fallback={<> </>}><PostPage /></Suspense>}
          />

          {/* legacy blog route redirect */}
          <Route
            path="/blog/*"
            element={(<Welcome />)}
          />

          <Route
            path="/reviews"
            element={(
              <Suspense fallback={<> </>}>
                <ReviewsPage />
              </Suspense>
            )}
          />
          <Route
            path="/reviews/credit-cards/:category"
            element={(
              <Suspense fallback={<> </>}>
                <ReviewsPage />
              </Suspense>
            )}
          />
          <Route
            path="/reviews/credit-cards/:category/:slug"
            element={(
              <Suspense fallback={<> </>}>
                <ReviewPage />
              </Suspense>
            )}
          />

          <Route
            path="/about"
            element={(
              <Suspense fallback={<> </>}>
                <About />
              </Suspense>
            )}
          />
          <Route
            path="/faq"
            element={(
              <Suspense fallback={<> </>}>
                <FaqPage />
              </Suspense>
            )}
          />
          <Route
            path="/calculator/form"
            element={(
              <Suspense fallback={<> </>}>
                <DemoDashboard />
              </Suspense>
            )}
          />
          <Route
            path="/calculator/selector"
            element={(
              <Suspense fallback={<> </>}>
                <DemoCardSelectorWrapper />
              </Suspense>
            )}
          />
          <Route
            path="/calculator/:demoType"
            element={(
              <Suspense fallback={<> </>}>
                <Results isDemoMode={true} />
              </Suspense>
            )}
          />
          <Route
            path="/results/:accountId/:ownCardId"
            element={(
              <RequireAuth>
                <Suspense fallback={<> </>}>
                  <Results />
                </Suspense>
              </RequireAuth>
            )}
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={(
              <RequireAuth>
                <Suspense fallback={<> </>}>
                  <Dashboard />
                </Suspense>
              </RequireAuth>
            )}
          />
          <Route
            path="/settings"
            element={(
              <RequireAuth>
                <Suspense fallback={<> </>}>
                  <Profile />
                </Suspense>
              </RequireAuth>
            )}
          />
          <Route
            path="/leaderboard"
            element={(
              <Suspense fallback={<> </>}>
                <Leaderboard />
              </Suspense>
            )}
          />
          <Route
            path="/healthcheck"
            element={(
              <div>page ok</div>
            )}
          />
        </Routes>
      </Main>
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainContentSectionComp);

MainContentSectionComp.propTypes = {
  appLoading: bool,
  appError: bool,
};

MainContentSectionComp.defaultProps = {
  appLoading: false,
  appError: false,
};
