import React from 'react';
import {
  Box, Text, Button,
} from 'grommet';
import { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { LightSecondaryButton } from './TopSection';
import { noOrphans } from '../../../utils';
import copy from '../../../utils/copy.json';

const { welcome } = copy;

const BottomForm = ({
  isSmall,
  isMedium,
  isSmallBusiness,
  isXlarge,
}) => {
  const isMediumDown = isMedium || isSmall;
  const navigate = useNavigate();
  const formCopy = isSmallBusiness ? welcome.bottomFormTextSB : welcome.bottomFormText;

  return (
    <Box
      pad={{ vertical: 'large', horizontal: isXlarge ? '' : 'medium' }}
      className="max-page-content"
      fill="horizontal"
      align="start"
      alignSelf="center"
    >
      <Box
        direction={isMediumDown ? 'column' : 'row'}
        gap="large"
        align="center"
      >
        <Box width={{ max: '70%' }}>
          <Text color="cfWhite" weight={600} size="large">
            {noOrphans(formCopy)}
          </Text>
        </Box>
        <Box direction="row" gap="medium">
          <Button
            alignSelf="start"
            primary
            as="a"
            label={<Text size="smedium" weight={600} color="cfNeutral900">{welcome.demoButtonCta}</Text>}
            href="calculator/form"
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault();
              amplitude.getInstance().logEvent('welcome-page.try-demo-button.click');
              window.scroll(0, 0);
            }}
          />
          <Box
            as="a"
            href="login"
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault();
              amplitude.getInstance().logEvent('welcome-page.try-demo-button.click');
              navigate('login');
            }}
          >
            <LightSecondaryButton
              label={<Text size="smedium">{welcome.getStarted}</Text>}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomForm;

BottomForm.propTypes = {
  isSmall: bool,
  isMedium: bool,
  isXlarge: bool,
  isSmallBusiness: bool,
};

BottomForm.defaultProps = {
  isSmall: false,
  isMedium: false,
  isXlarge: false,
  isSmallBusiness: false,
};
